// colors
$blue: #74c0e3;

// fonts
$serif: "Lora", serif;
$sans: "Poppins", sans-serif;
$mono: "Cousine", monospace;

// line spacing
$spacing: 1.75;

// transitions
$fast: 0.2s ease;
$slow: 0.5s ease;

// page content column width
$page: 1100px;
$page-narrow: 800px;
$page-wide: 1500px;
